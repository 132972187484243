import { Controller } from "@hotwired/stimulus";
import React from "react";
import { createRoot } from "react-dom/client";

import { Viewer } from "src/admin/catalog/viewer";

export default class extends Controller<HTMLDivElement> {
  static values = {
    catalogUrl: String,
    outOfStockListUrl: String,
    editorUrl: String,
  }

  declare catalogUrlValue?: string;
  declare outOfStockListUrlValue?: string;
  declare editorUrlValue?: string;

  connect() {
    const root = createRoot(this.element);
    root.render(React.createElement(Viewer, {
      catalogUrl: this.catalogUrlValue,
      outOfStockListUrl: this.outOfStockListUrlValue,
      editorUrl: this.editorUrlValue,
    }));
  }
}
