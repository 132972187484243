import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["parent", "child"];

  declare hasParentTarget: boolean;
  declare parentTarget: HTMLInputElement;
  declare childTargets: HTMLElement[];

  connect(): void {
    if (this.hasParentTarget) {
      this.apply();
    }
  }

  apply(): void {
    const selected = this.parentTarget.checked === false ? "" : this.parentTarget.value;
    for (const child of this.childTargets) {
      if (
        child.dataset["visibleForValue"] !== undefined && child.dataset["visibleForValue"] == selected ||
        child.dataset["visibleForValues"] !== undefined && child.dataset["visibleForValues"].split(",").includes(selected)
      ) {
        child.classList.remove('is-hidden');
      } else {
        child.classList.add('is-hidden');
      }
    }
  }
}
