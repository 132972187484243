// Action Cable provides the framework to deal with WebSockets in Rails.
import * as ActionCable from '@rails/actioncable'
import { createConsumer } from '@rails/actioncable';

declare global {
  interface Window {
    adminCable: ActionCable.Consumer;
  }
}

export function adminCable(): ActionCable.Consumer {
  ActionCable.logger.enabled = true
  return window.adminCable ??= createConsumer('/admin_cable');
}
