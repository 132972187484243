import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { baseUrl: String }
  static targets = [ "startAt", "endAt", "invert", "visualisation" ]

  declare baseUrlValue: string;
  declare startAtTarget: HTMLInputElement;
  declare endAtTarget: HTMLInputElement;
  declare invertTarget: HTMLInputElement;
  declare visualisationTarget: HTMLDivElement;

  connect() {
    this.updateVisualisationSrc()
  }

  updateVisualisationSrc() {
    this.visualisationTarget.setAttribute("src", `${this.baseUrlValue}/?start_at=${this.startAtTarget.value}&end_at=${this.endAtTarget.value}&invert=${this.invertTarget.checked}`)
  }
}
