import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["parent", "child"];

  declare hasParentTarget: boolean;
  declare parentTarget: HTMLInputElement;
  declare childTargets: HTMLFormElement[];

  connect(): void {
    if (this.hasParentTarget) {
      this.apply();
    }
  }

  apply(): void {
    const selected = this.parentTarget.checked === false ? "" : this.parentTarget.value;
    for (const child of this.childTargets) {
      if (
        child.dataset["enableForValue"] !== undefined && child.dataset["enableForValue"] == selected ||
        child.dataset["enableForValues"] !== undefined && child.dataset["enableForValues"].split(",").includes(selected)
      ) {
        child.disabled = false;
      } else {
        child.disabled = true;
      }
    }
  }
}
