import { Controller } from "@hotwired/stimulus";

type Event = {
  target: HTMLInputElement;
}

export default class extends Controller {
  static targets = [ "subject" ]
  static classes = [ "checked", "unchecked" ] 

  // The DOM element on which to toggle classes
  declare subjectTarget : HTMLElement;
  // The sets of classes to toggle on/of on the subject based on
  // the "checked" state of the checkbox that triggers the toggle
  declare checkedClasses : string[];
  declare uncheckedClasses : string[];

  toggle(event: Event) {
    if (event.target.checked) {
      this.subjectTarget.classList.remove(...this.uncheckedClasses);
      this.subjectTarget.classList.add(...this.checkedClasses);
    } else {
      this.subjectTarget.classList.remove(...this.checkedClasses);
      this.subjectTarget.classList.add(...this.uncheckedClasses);
    }
  }
}
