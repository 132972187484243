import { Controller } from "@hotwired/stimulus"
import { FrameElement } from "@hotwired/turbo"

export default class extends Controller<FrameElement> {
  static values = {
    intervalSeconds: { type: Number, default: 30 },
  }

  declare intervalSecondsValue: number
  declare timeout: number

  connect() {
    this.element.addEventListener("turbo:before-frame-render", () => {
      this.timeout = window.setTimeout(() => {
        this.element.reload();
      }, this.intervalSecondsValue * 1000);
    })
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}
