import * as Sentry from "@sentry/browser";

interface ResolvedAddress {
  address_provider: string;
  address: string;
  full_address: string;
  partial_match?: boolean;

  business_name?: string;
  street?: string;
  locality?: string;
  sublocality?: string;
  postal_code?: string;

  place_id: string;
  address_type?: string;

  latitude: number;
  longitude: number;
}

class GoogleAutocompleteResolver {

  static defaultAutoComplete(addressField: HTMLInputElement) : google.maps.places.Autocomplete {
    return new google.maps.places.Autocomplete(addressField, {
      fields: ['address_components', 'name', 'formatted_address', 'geometry', 'place_id', 'types'],
      bounds: GoogleAutocompleteResolver.defaultBounds(),
      strictBounds: true,
    });
  }

  static defaultBounds(): google.maps.LatLngBounds {
    return new google.maps.LatLngBounds(
      new google.maps.LatLng(-52.722466, 165.743764),
      new google.maps.LatLng(-28.877322, -175.123508),
    );
  }

  async resolveFromAutocomplete(inputAddress: string, place?: google.maps.places.PlaceResult): Promise<ResolvedAddress | null> {
    if (!place?.address_components || !place?.types) return null;

    const result = this.resolveFromPlace(place);

    // Customer chose from the autocomplete menu; inputAddress is already the full autocompleted address.
    // Sometimes for addresses with subpremise components, Google gives us back the coordinates for the street
    // as a whole, not the specific house. This can cause us to mis-place the address in the wrong block/suburb
    // or direct drivers to the wrong coordinates. But surprisingly, it turns out that calling their geocoder
    // API again with the full autocompleted address gives us back the right location. It doesn't always do it,
    // even with subpremises, and even with "real" subpremises, but we don't know when; there are also a smaller
    // number of cases in which we see it for subpremises on highways, which come back as ["route"]; it's
    // possible there are others, but we haven't seen it.
    if (place.types[0] == "subpremise" || place.types[0] == "route") {
      const geocoderResults = await this.geocode({ address: place.formatted_address, bounds: GoogleAutocompleteResolver.defaultBounds() }).catch((status) => {
        // AFAIK we shouldn't ever see ZERO_RESULTS or NOT_FOUND for a place ID, so everything here is an error
        Sentry.captureMessage(`GoogleAutocompleteResolver: re-geocoding place returned ${status}`, { extra: {
          inputAddress: inputAddress,
          formatted_address: place.formatted_address,
          place_id: place.place_id,
        } });
        return [];
      });

      if (geocoderResults.length > 0 && geocoderResults[0].geometry?.location) {
        result.latitude = geocoderResults[0].geometry.location.lat();
        result.longitude = geocoderResults[0].geometry.location.lng();
      }
    }

    if (place.name && place.formatted_address && !place.formatted_address.startsWith(place.name)) {
      // If the place has a name *other than* its street address, this generally means it's a named
      // place such as a business or POI (most of which we will reject in the server-side resolver).
      // So put the place name in the business name field and keep the formatted_address rather than
      // the autocomplete input, which is often just a partial address (eg. for the DE office we get
      // "Delivereasy Office Taranaki Street" not "Delivereasy Office 79 Taranaki St").
      result.business_name = place.name;
    } else {
      // The place result's formatted_address usually matches the autocompleted text exactly (except
      // for the postcode/country), but it doesn't in the case where they entered a unit number at a
      // property that isn't actually a separate title - the place result has just the title's street
      // number, whereas the autocompleted input address retains the unit number. So keeping the input
      // address is better than taking it from the given place data in that case. That said, we do
      // want to normalize a little here, such as replacing the short form of street names with long.
      result.address = this.normalizeStreetName(inputAddress, place);
    }

    return result;
  }

  async resolveFromGeocoding(inputAddress: string): Promise<ResolvedAddress | null> {
    const geocoderResults = await this.geocode({ address: inputAddress, bounds: GoogleAutocompleteResolver.defaultBounds() }).catch((status) => {
      // Normal to not find anything in this case, but not hit actual errors.
      if (status != google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        Sentry.captureMessage(`GoogleAutocompleteResolver: geocoding address returned ${status}`, { extra: {
          inputAddress: inputAddress,
        } });
      }
      return [];
    });

    if (geocoderResults.length == 0) {
      console.log("Couldn't geocode address", inputAddress);
      return null;
    }

    const result = this.resolveFromPlace(geocoderResults[0]);
    result.partial_match = geocoderResults[0].partial_match;
    return result;
  }

  private resolveFromPlace(place: google.maps.places.PlaceResult | google.maps.GeocoderResult): ResolvedAddress {
    return {
      address_provider: "google",

      // Most of the fields we ?? here are always present since we specified them in the types option
      // to Autocomplete, but we pretend to support undefined values here to make typescript happy.
      full_address: place.formatted_address ?? "",

      // Some callers choose to override this element.
      address: place.formatted_address ?? "",

      street: this.findAddressComponent("route", place.address_components),
      sublocality: this.findAddressComponent("sublocality_level_1", place.address_components),
      locality: this.findAddressComponent("locality", place.address_components) ??
                this.findAddressComponent("postal_town", place.address_components) ??
                this.findAddressComponent("administrative_area_level_1", place.address_components),
      postal_code: this.findAddressComponent("postal_code", place.address_components),

      // We use this in the AddressResolver. Again, always present in reality.
      address_type: place.types?.join(','),

      // Again, these fields are actually always present since we specified geometry in the types
      // option to Autocomplete, but make typescript happy.
      place_id: place.place_id ?? "",
      latitude: place.geometry?.location?.lat() || 0,
      longitude: place.geometry?.location?.lng() || 0,
    }
  }

  private findAddressComponent(type: string, components?: google.maps.GeocoderAddressComponent[]) {
    return components?.find(c => c.types.includes(type) && c.long_name != '')?.long_name;
  }

  private normalizeStreetName(inputAddress: string, place?: google.maps.places.PlaceResult) {
    const streetComponent = place?.address_components?.find(c => c.types.includes('route') && c.long_name != '' && c.short_name != '');

    if (streetComponent && inputAddress.includes(streetComponent.short_name) && !inputAddress.includes(streetComponent.long_name)) {
      return inputAddress.replace(streetComponent.short_name, streetComponent.long_name);
    } else {
      return inputAddress;
    }
  }

  private geocode(request: google.maps.GeocoderRequest) : Promise<google.maps.GeocoderResult[]> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results != null) {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  }
}

export { GoogleAutocompleteResolver, ResolvedAddress };
