import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["clipboard"];

  declare clipboardTarget : HTMLSpanElement

  copyToClipboard() {
    const copyText = this.data.get("content");

    if (copyText !== null) {
      navigator.clipboard.writeText(copyText);
      this.clipboardTarget.setAttribute("data-tooltip", "Copied!");
    }
  }

  resetTooltip() {
    this.clipboardTarget.setAttribute("data-tooltip", "Copy to clipboard");
  }
}
