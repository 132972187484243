import { Controller } from "@hotwired/stimulus";

import { adminCable } from "src/admin_cable";

interface TaskUpdate {
  status: string;
  progress: number;
}

export default class BackgroundTaskController extends Controller implements ActionCable.Mixin {
  static targets = ["status", "progress", "progressBar"];

  declare statusTarget : HTMLSpanElement;
  declare progressTarget : HTMLSpanElement;
  declare progressBarTarget : HTMLProgressElement;

  subscription?: ActionCable.Subscription<ActionCable.Consumer> = undefined;
  taskId: string | null = null;

  connect() : void {
    this.taskId = this.data.get("id");
    if (this.taskId === null) {
      throw "Can't read taskID from DOM";
    }

    const statusTarget = this.statusTarget;
    const progressTarget = this.progressTarget;
    const progressBarTarget = this.progressBarTarget;

    this.subscription = adminCable().subscriptions.create<unknown>({
      channel: "BackgroundTasksChannel",
      background_task_id: this.taskId
    }, {
      connected() : void {
        this.perform("force_update");
      },
      received(data: TaskUpdate) : void {
        if (data.status == "Finished") {
          window.location.reload();
          return;
        }

        if (data.progress <= 0)
          progressBarTarget.removeAttribute("value");
        else
          progressBarTarget.setAttribute("value", data.progress.toString());

        statusTarget.innerText = data.status;

        if (data.progress <= 0)
          progressTarget.innerText = "unknown";
        else
          progressTarget.innerText = data.progress.toString();
      }
    });
  }

}
